.csv-input {
  padding: 5px;
  display: block;
  margin: 5px auto;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.container-input {
  text-align: center;
  padding: 1px;
  margin: 2px auto;
}